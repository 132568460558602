
import * as React from "react";
import ApplicationContainer from "../containers/apply";
import DefaultLayout from "../layouts/default";
export default () => {
    return (
        <>
            <DefaultLayout title="Apply Now - No Credit Check ">
                <ApplicationContainer
                    title="Cash Loans | Bad Credit Accepted
                    <br/>Get $1,000 - $50,000 Same Day"
                    description="How Much Do You Need?<br/>"
                />
            </DefaultLayout>

        </>
    );
};